function Footer() {
    return (
      <div className="footer">
        ©{new Date().getFullYear()} Made with ❤️ by Deep Dhakate. All Rights
        Reserved.
      </div>
    );
  }

  
  export default Footer;